export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {  
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",      
  GET_APP_INITIAL_DATA_REQUEST: "GET_APP_INITIAL_DATA_REQUEST",
  GET_APP_INITIAL_DATA_SUCCESS: "GET_APP_INITIAL_DATA_SUCCESS",
  GET_APP_INITIAL_DATA_FAILURE: "GET_APP_INITIAL_DATA_FAILURE",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",  
  SET_REDIRECT: "SET_REDIRECT",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const dataConstants = {  
  GET_APPOINTMENTS_REQUEST: "GET_APPOINTMENTS_REQUEST",
  GET_APPOINTMENTS_SUCCESS: "GET_APPOINTMENTS_SUCCESS",
  GET_APPOINTMENTS_FAILURE: "GET_APPOINTMENTS_FAILURE",
  GET_APPOINTMENT_REQUEST: "GET_APPOINTMENT_REQUEST",
  GET_APPOINTMENT_SUCCESS: "GET_APPOINTMENT_SUCCESS",
  GET_APPOINTMENT_FAILURE: "GET_APPOINTMENT_FAILURE",
  GET_TOKENS_REQUEST: "GET_TOKENS_REQUEST",
  GET_TOKENS_SUCCESS: "GET_TOKENS_SUCCESS",
  GET_TOKENS_FAILURE: "GET_TOKENS_FAILURE",
  CREATE_APPOINTMENT_REQUEST: "CREATE_APPOINTMENT_REQUEST",
  CREATE_APPOINTMENT_SUCCESS: "CREATE_APPOINTMENT_SUCCESS",
  CREATE_APPOINTMENT_FAILURE: "CREATE_APPOINTMENT_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}