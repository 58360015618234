import { dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAppointments = () => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_APPOINTMENTS_REQUEST });
      const { data } = await Axios.get(`/patient/appointment`);            
      dispatch({ 
        type: dataConstants.GET_APPOINTMENTS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_APPOINTMENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAppointment = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_APPOINTMENT_REQUEST });
      const { data } = await Axios.get(`/patient/appointment/${id}`);            
      dispatch({ 
        type: dataConstants.GET_APPOINTMENT_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_APPOINTMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }

}

export const getTokens = () => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_TOKENS_REQUEST });
      const { data } = await Axios.get(`/patient/token`);            
      dispatch({ 
        type: dataConstants.GET_TOKENS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_TOKENS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const createAppointment = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.CREATE_APPOINTMENT_REQUEST });
      const { data } = await Axios.post(`/patient/appointment`, form);
      dispatch({ 
        type: dataConstants.CREATE_APPOINTMENT_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.CREATE_APPOINTMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}